import {
  CommentsIcon,
  DisagreeIcon,
  HandShakeSimpleIcon,
} from '@mybridge/icons';
import { Button } from '@mybridge/ui/button';
import { HStack } from '@mybridge/ui/layout';
import { useContext } from 'react';
import {
  PostCommentContext,
  PostCommentFormContext,
} from 'v4/components/comment/comment-form/context';
import { PostCommentRowContext } from '../context';
import { Text } from '@mybridge/ui/text';
import { PostBoxContext } from 'v4/components/post/context';
import { Tooltip } from '@mybridge/ui';
import { useSelector } from 'react-redux';

export const PostCommentRowFooterActions = ({ ...props }) => {
  const {
    commentBoxVisible,
    setCommentBoxVisible,
    handleLike,
    handleDislike,
    likeLoading,
    dislikeLoading,
    level,
    comment: { liked_by, dislike_by },
  } = useContext(PostCommentRowContext);
  const { userProfileInfo } = useSelector((s) => s.userProfile) ?? {};
  const { commentsAllowed } = useContext(PostBoxContext);
  const { textInputRef } = useContext(PostCommentFormContext);
  const likedByMe = liked_by?.find((lb) => lb?.id === userProfileInfo?.id);
  const dislikedByMe = dislike_by?.find((lb) => lb?.id === userProfileInfo?.id);
  return (
    <HStack py={1} borderTop="1px solid" borderColor="gray.200" {...props}>
      {level < 3 ? (
        <HStack flex={1} justifyContent="center">
          <Tooltip
            label={commentsAllowed ? '' : 'Comments disabled by author!'}
          >
            <Button
              w="100%"
              variant="ghost"
              leftIcon={<CommentsIcon />}
              onClick={(e) => {
                setCommentBoxVisible(!commentBoxVisible);
                setTimeout(() => {
                  textInputRef?.current?.focus?.();
                }, 100);
              }}
              iconSpacing={[0, 0, 2]}
              isDisabled={!commentsAllowed}
            >
              <Text display={['none', 'none', 'block']}>Comment</Text>
            </Button>
          </Tooltip>
        </HStack>
      ) : (
        ''
      )}
      <HStack flex={1} justifyContent="center">
        <Button
          isLoading={likeLoading}
          onClick={handleLike}
          w="100%"
          variant="ghost"
          leftIcon={
            <HandShakeSimpleIcon color={likedByMe ? 'rgb(148, 176, 150)' : 'rgb(179, 140, 140)'} />
          }
          iconSpacing={[0, 0, 2]}
        >
          <Text display={['none', 'none', 'block']}>
            {likedByMe ? 'Agreed' : 'Agree'}
          </Text>
        </Button>
      </HStack>
      <HStack flex={1} justifyContent="center">
        <Button
          isLoading={dislikeLoading}
          w="100%"
          variant="ghost"
          onClick={handleDislike}
          leftIcon={<DisagreeIcon color={dislikedByMe ? 'red' : '#B38C8C'} />}
          iconSpacing={[0, 0, 2]}
        >
          <Text display={['none', 'none', 'block']}>
            {dislikedByMe ? 'Disagreed' : 'Disagree'}
          </Text>
        </Button>
      </HStack>
    </HStack>
  );
};
